import { ethers } from 'ethers';
import { graphURL } from '../config';

import axios from 'axios';

async function treeViewActivities(userId, filterSD, filterED, filterSlotFrom, filterSlotTo) {
    const nowTimestamp = Math.floor(Date.now() / 1000);

    // Calculate the timestamp for 24 hours ago
    
    const oneDayInSeconds = 24 * 60 * 60;
    const timestamp24HoursAgo = nowTimestamp - oneDayInSeconds;

    // blockTimestamp_gte: ${filterSD}, blockTimestamp_lte: ${filterED}, 
    // blockTimestamp_gte: ${filterED}, blockTimestamp_lte: ${filterSD}, 

    const options = {
        method: 'POST',
        url: graphURL,
        headers: {
            'content-type': 'application/json'
        },
        data: {
            query: `{
                treePayoutEvents(where: {to: ${userId}, level_gte: ${filterSlotFrom}, level_lte: ${filterSlotTo}}) {
                  id
                  from
                  amount
                  transactionHash
                  level
                  blockTimestamp
                }
                
                directPaidEvents(where: {to: ${userId}, level_gte: ${filterSlotFrom}, level_lte: ${filterSlotTo}}) {
                  id
                  from
                  amount
                  transactionHash
                  level
                  blockTimestamp
                }
              }`
        }
    };

    const response = await axios.request(options);
    const res = response.data; // Response received from the API
    console.log(res);
    const treePayoutEvents = res.data.treePayoutEvents || [];
    const directPaidEvents = res.data.directPaidEvents || [];

    const sumTreePayoutEvents = treePayoutEvents.reduce((sum, event) => sum + parseFloat(ethers.utils.formatEther(event.amount)), 0);
    const sumDirectPaidEvents = directPaidEvents.reduce((sum, event) => sum + parseFloat(ethers.utils.formatEther(event.amount)), 0);

    let mergedArray = treePayoutEvents.concat(directPaidEvents)

    return {sumDirectPaidEvents, sumTreePayoutEvents, treePayoutEvents, directPaidEvents, mergedArray};
}

export default treeViewActivities;