import React, { useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './Levels.css'
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react';
import tether from '../../Assets/images/tether.png'
import useActiveWeb3React from '../../hooks/useWeb3';
import { ethers } from 'ethers';
import { useStakeContract, useUniLevelContract } from '../../hooks/useContract';
import { toast } from 'react-toastify';
import { cilUserFollow } from '@coreui/icons'
import CIcon from '@coreui/icons-react'


function Levels() {
    const { library, chainId, account } = useActiveWeb3React();
    const [userId, setUserId] = useState(0);
    const [userAddress, setUserAddress] = useState('');
    const [levels, setLevels] = useState([]);
    const [defaultLevels, setDefaultLevels] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const planC = useStakeContract();
    const planUni = useUniLevelContract();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
    }, [setUserId, location])

    useEffect(() => {
        if (planC && userId !== 0) {
            fetSLOTLevels();
        }
    }, [userId, planC])

    const fetSLOTLevels = useCallback(async () => {
        let SlotLevelDetails = document.getElementById('SlotLevelDetails');
        SlotLevelDetails.classList.add('loaderData');
        let userAddress;
        userAddress = await planC.getAddressById(userId);

        const userInfoResponse = await planC.userInfos(userAddress);

        if (userInfoResponse.joined == false) {
            toast.error("This user id does not exist");
            navigate("/");
        }

        const levelBought = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();

        const defaultItems = [];
        for (let j = 1; j <= levelBought; j++) {
            let defaultData = [];
            defaultData.level = j;
            let levelData = [];
            for (let i = 1; i <= 10; i++) {
                let levelKeyData = [];
                // let levelSlotEarning = await planUni.userSlotLevelEarnings(userAddress, j, i);
                // levelKeyData.slotLevelEarning = ethers.utils.formatEther(levelSlotEarning);
                levelKeyData.level = i;
                levelData.push(levelKeyData)
            }
            defaultData.levelEarning = levelData;
            defaultItems.push(defaultData)
        }
        setDefaultLevels(defaultItems);
        SlotLevelDetails.classList.remove('loaderData');


        console.log("defualt", defaultItems);

        const listItems = [];
        for (let j = 1; j <= levelBought; j++) {
            let slotUsers = await planUni.userSlotCount(userAddress, j);
            let slotEarning = await planUni.userSlotEarnings(userAddress, j);

            let data = [];
            data.slotUser = ethers.BigNumber.from(slotUsers).toNumber();
            data.slotEarning = ethers.utils.formatEther(slotEarning);//ethers.BigNumber.from().toNumber();

            let levelData = [];
            for (let i = 1; i <= 10; i++) {
                let levelKeyData = [];
                // let levelSlotEarning = await planUni.userSlotLevelEarnings(userAddress, j, i);
                // levelKeyData.slotLevelEarning = ethers.utils.formatEther(levelSlotEarning);
                levelKeyData.level = i;
                levelData.push(levelKeyData)
            }

            data.levelEarning = levelData;
            listItems.push(data)
        }
        setLevels(listItems);



        console.log("slot", listItems);
    });
    return (
        <>
            <section className="treeLevels">
                <div className='titleBox'>
                    <h2>Level Income</h2>
                </div>
                <div className="rowtreebox" id='SlotLevelDetails'>
                    <CAccordion activeItemKey={0}>
                        {levels.length == 0 ? defaultLevels.map((a, index) => {
                            return <CAccordionItem itemKey={index} key={index}>
                                <CAccordionHeader className='treebox'>
                                    <div className='row'>
                                        <div className='col-6'><b>Slot {index + 1}</b></div>
                                        <div className='col-6'><span className='loaderDataSmall'></span><span className='loaderDataSmall'></span></div>
                                    </div>
                                </CAccordionHeader>
                                <CAccordionBody>
                                    <div className='row inrBoxList'>
                                        {a.levelEarning.map((b, k) => {
                                            return <div className='col-12 col-sm-3'>
                                                <NavLink to={"/levelsdetails?user_id=" + userId + "&slot=" + (index + 1) + "&level=" + b.level}>
                                                    <div className='row'>
                                                        <div className='col-12'><b>Level {b.level}</b></div>
                                                        {/* <div className='col-6'><span></span></div> */}
                                                    </div>
                                                </NavLink>
                                            </div>
                                        })}
                                    </div>
                                </CAccordionBody>
                            </CAccordionItem>
                        }) : levels.map((a, index) => {
                            return <CAccordionItem itemKey={index} key={index}>
                                <CAccordionHeader className='treebox'>
                                    <div className='row'>
                                        <div className='col-6'><b>Slot {index + 1}</b></div>
                                        <div className='col-6'><span>${parseFloat(a.slotEarning).toFixed(2)} </span><span className='ms-2'><CIcon icon={cilUserFollow} size="xs" /> {a.slotUser}</span></div>
                                    </div>
                                </CAccordionHeader>
                                <CAccordionBody>
                                    <div className='row inrBoxList'>
                                        {a.levelEarning.map((b, k) => {
                                            return <div className='col-12 col-sm-3'>
                                                <NavLink to={"/levelsdetails?user_id=" + userId + "&slot=" + (index + 1) + "&level=" + b.level}>
                                                    <div className='row'>
                                                        <div className='col-12'><b>Level {b.level}</b></div>
                                                        {/* <div className='col-6'><span></span></div> */}
                                                    </div>
                                                </NavLink>
                                            </div>
                                        })}
                                    </div>
                                </CAccordionBody>
                            </CAccordionItem>
                        })}
                    </CAccordion>
                </div>
            </section>
        </>
    )
}

export default Levels