export const nftAddress = {
    1: "0xaCeB4A3CFbCF2ed2cC9b1080A00fF865E02F326d",
    2: "0x4d73739892Ef517Df36054230d4D2478674A6B11",
    3: "0x1Ed06d9A56efc6f2Eb6889113254B26223908Ff5",
    4: "0x07F531F1e461958fA7ad1dfAf2b1726255cA2C71",
    5: "0xce0E7b6a8f7937e58ce422DE43AcBa5a2E49BE86",
    6: "0xa329ba2a0683d660A1D5954E4f543017c638E90d",
    7: "0xA8d0c7C013af90F4609D90C4B6B6552F66E00672",
    8: "0xa013979e826d5ec2c2Ce55098cd7d5f72Af2afe4",
    9: "0x9461715Ef35b8795B6498A25A9E00d8d366b9254",
    10: "0xCdaa67c280a006ae7A67e2B47a79fb2988DdD8a2",
    11: "0xa06933E971eC575cAA7D5f998f5A076DB9e2633F",
    12: "0x390f2Eb21be966560702042AC1e0297205114Be7",
    13: "0xDe63f4803Fbdc643cE9EAddbFe96fd2EfD6B34Ff",
    14: "0x4Fc10D6F8928337B0dFA56eB6B84C131468588fb",
    15: "0x834b70F2CD9C77022071Bd40BAD985A6C1E8975c"
}