import { useEffect, useState, useRef, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { simpleRpcProvider } from '../utils/providers'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
    const { library, chainId, account, ...web3React } = useWeb3React()
    const refEth = useRef(library)
    const [provider, setProvider] = useState(library || simpleRpcProvider)

    const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('user');

    useEffect(() => {
        if (library !== refEth.current) {
            setProvider(library || simpleRpcProvider)
            refEth.current = library
        }
    }, [library])

    return useMemo(() => {
        console.log("account updated", account ?? myParam)
        return { library: provider, account: myParam ?? account, chainId: chainId ?? parseInt(process.env.REACT_APP_CHAIN_ID, 10), ...web3React }
    }, [account, provider, chainId, web3React])
}

export default useActiveWeb3React