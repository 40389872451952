import { uniGraphURL } from '../../config';
import axios from 'axios';

export const getUserLevelTreeCountUni = async ( userAddress, slot, level ) => {
    try {
        const options = {
            method: 'POST',
            url: uniGraphURL,
            headers: {
                'content-type': 'application/json'
            },
            data: {
                query: `{
                    unilevelUsers(where:{user:"${userAddress}", slot: ${slot}, level: ${level}}) {
                        id
                        downUser
                        slot
                        level
                        referrer
                        amount
                        transactionHash
                    }
                }`
            }
        };
    
        const response = await axios.request(options);
        console.log(response.data.data.unilevelUsers)
        return response.data.data.unilevelUsers
    } catch (error) {
        console.log(error)
        return []
    }
}