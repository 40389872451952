import React, { useRef, useState } from 'react';
import loaderGif from '../../Assets/images/loading.gif'
import './RegModal.css'

const Loader = () => {
    return (
        <div className="loader"><img src={loaderGif} alt="loader GIF" /></div>
    )
}


const RegModal = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    const buttonRef = useRef(null)

    return (
        <>
            <div className="modal fade regModalToggle" id="regModalToggle" aria-hidden="true" aria-labelledby="regModalToggleLabel" tabIndex="1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="regModalToggleLabel">Guidelines</h5>
                            <button type="button" className="btn-close" ref={buttonRef} data-coreui-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='inrBodySroll'>
                                <p>Hello, and welcome to OX Cash NFT Pro. Our platform offers some of the rarest NFTs with an OX theme, and we appreciate you choosing us as your source for purchasing them.</p>
                                <p>Before proceeding to our website, we would like to offer a few recommendations to ensure a safe and enjoyable experience for you:</p><br></br>
                                <p>- Please take the time to read our Ox Cash NFT Pro presentation thoroughly before activating your profile.</p>
                                <p>- We strongly advise double-checking all data provided before purchasing any NFTs to avoid errors or misunderstandings.</p>
                                <p>- Do not feel pressured to buy any NFTs by anyone, as it is essential that all purchases are made voluntarily and with a clear mind.</p>
                                <p>- Plan your finances with the utmost diligence and only buy NFTs that fall within your budget. Do not take any financial risks or borrow money to buy NFTs.</p>
                                <p>- Never use the last of your life savings to buy NFTs, buying NFTs  should not put you in a financially vulnerable position.</p>
                                <p>- Please remember that any decisions made regarding NFT purchases are irreversible on the blockchain, so think wisely before proceeding.</p><br></br>
                                <p>We appreciate your attention to these recommendations and hope that you enjoy your experience on our platform. Thank you again for choosing OX Cash NFT Pro.</p>
                                <h4>Terms and Conditions for OX Cash NFT Pro</h4>
                                <p>These Terms and Conditions ("Agreement") constitute a legal agreement between you ("User", "You") and OX Cash NFT Pro ("Company", "We", "Us", "Our"). By accessing or using the OX Cash NFT Pro website or any services provided here, you agree to be bound by this Agreement.</p>
                                <h4>Definitions</h4>
                                <p>1.1 "NFT" means Non-Fungible Token.</p>
                                <p>1.2 "Platform" means the OX Cash NFT Pro website, which provides a marketplace for the purchase of NFTs.</p>
                                <p>1.3 "User" means any person who accesses or uses the Platform.</p>
                                <p>1.4 "Buyer" means a User who purchases NFTs on the Platform.</p>
                                <strong>Platform Use</strong>
                                <p>2.1 Use of the Platform is subject to these Terms and Conditions, the Privacy Policy, and any other policies or guidelines posted on the Platform.</p>
                                <p>2.2 By using the Platform, you represent and warrant that you have the legal capacity to enter into this Agreement and to comply with its terms.</p>
                                <p>2.3 You agree to use the Platform only for lawful purposes and in compliance with all applicable laws and regulations.</p>
                                <p>2.4 You are solely responsible for any transactions you make on the Platform.</p>
                                <strong>NFT Transactions</strong>
                                <p>3.1 NFTs listed for sale on the Platform are sold by the platform directly to the Buyer. The Platform is not a party to any transaction and thus it is not responsible for any aspect of such transactions.</p>
                                <p>3.2 The Platform does not guarantee the authenticity, legality, or quality of any NFTs listed on the Platform. Buyers should conduct their own due diligence before purchasing any NFTs.</p>
                                <p>3.3 The Company does not provide any warranties or guarantees regarding NFTs listed on the Platform, including but not limited to their ownership, title, or authenticity.</p>
                                <p>3.4 The Owner is solely responsible for ensuring that they have the legal right to sell any NFTs listed on the Platform.</p>
                                <p>3.5 Buyers are solely responsible for ensuring that they have the legal right to purchase any NFTs listed on the Platform.</p>
                                <p className='mb-0'>3.6 The Buyer will not be able to sell any NFTs back to the Platform, Owner, Promoter, Developer, or any other team member.</p>
                            </div>
                            {/* <form onSubmit={handleSubmit}> */}
                                <div className="form-check mt-3 mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                        checked={isChecked}
                                        onChange={(event) => setIsChecked(event.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        I agree to the terms and condition
                                    </label>
                                    {!isChecked && (
                                        <div className="error-message">
                                            Please check the box to agree to the terms and conditions
                                        </div>
                                    )}
                                </div>
                                {
                                    isChecked && <button id='nextButton' onClick={async () => {
                                        await props.onSign()
                                        if ( buttonRef.current ) buttonRef.current.click()
                                    }} className="am_btn mt-3">
                                        {props.isInTrn && <Loader />}
                                        {!props.isInTrn && props.buttonText}
                                    </button> 
                                }
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegModal