import React, { useCallback, useEffect, useState } from 'react'
import './MyNfts.css'
import tether from '../../Assets/images/tether.png'


import useActiveWeb3React from '../../hooks/useWeb3'
import { useLocation, useNavigate } from 'react-router'
import { useStakeContract } from '../../hooks/useContract'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'


import { NFTDetail } from './NFTDetail'

function MyNfts() {
    const { library, chainId, account } = useActiveWeb3React();
    const [userId, setUserId] = useState(0);
    const [userAddress, setUserAddress] = useState('');
    const [levelBought, setLevelBought] = useState(0);
    const [nfts, setNfts] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const planC = useStakeContract();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
    }, [setUserId, location])

    useEffect(() => {
        if (planC && userId !== 0) {
            fetchNFTs();
        }
    }, [userId, planC])

    const fetchNFTs = useCallback(async () => {
        let NFTSLevelDetails = document.getElementById('NFTSLevelDetails');
        NFTSLevelDetails.classList.add('loaderData');
        let _userAddress;
        _userAddress = await planC.getAddressById(userId);

        setUserAddress(_userAddress)

        const userInfoResponse = await planC.userInfos(_userAddress);
        // console.log("u", userInfoResponse);
        if (userInfoResponse.joined == false) {
            toast.error("This user id does not exist");
            navigate("/");
        }

        const levelBought = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();
        setLevelBought(levelBought)
        const listItems = [];
        for (let j = 0; j < levelBought; j++) {
            listItems.push(j)
        }
        console.log(listItems);
        setNfts(listItems);
        NFTSLevelDetails.classList.remove('loaderData');
    });

    return (
        <>
            <section className="treeAeromatix">
                <div className='titleBox'>
                    <h2>My Nfts</h2>
                </div>
                <div className="row rowtreebox" id='NFTSLevelDetails'>
                    {nfts.map((a, index) => {
                        return <NFTDetail wallet={userAddress} slot={index+1} />
                    })}
                </div>
            </section>
        </>
    )
}

export default MyNfts