import { ethers } from 'ethers';
import { graphURL } from '../config';
import axios from 'axios';

async function fetchUserIncome(userId) {
  const nowTimestamp = Math.floor(Date.now() / 1000);

  // Calculate the timestamp for 24 hours ago
  const oneDayInSeconds = 24 * 60 * 60;
  const timestamp24HoursAgo = nowTimestamp - oneDayInSeconds;

  const options = {
      method: 'POST',
      url: graphURL,
      headers: {
          'content-type': 'application/json'
      },
      data: {
          query: `{
              oxUsers(where:{userID: ${userId}}) {
                id
                user
                userID
                totalEarnings
                totalDirectIncome
                totalTreeIncome
              }
            }`
      }
  };

  const response = await axios.request(options);
  const res = response.data;

  console.debug(res)
  const sumDirectPaidEvents = res.data.oxUsers[0].totalDirectIncome;
  const sumTreePayoutEvents = res.data.oxUsers[0].totalTreeIncome;

  return {sumDirectPaidEvents: sumDirectPaidEvents/1e18, sumTreePayoutEvents: sumTreePayoutEvents/1e18};
}

export default fetchUserIncome;