import React from 'react';
import { CRow, CCol, } from '@coreui/react'
import { Home, Info, EvStationSharp, PoolTwoTone, People, QueryStatsIcon } from '@material-ui/icons';
import { FaTelegramPlane, FaRegEnvelope, FaTwitter, FaMediumM, FaInstagram, FaRedditAlien, FaFacebookF, FaYoutube } from "react-icons/fa";
import './Footer.css'
const Footer = () => {

    return (
        <footer>
            <div className='container'>
                <CRow className='align-items-center'>
                    <CCol sm={12} md={4}><p>© 2023 All Rights Reserved</p></CCol>
                    <CCol sm={12} md={8} className="text-right">
                        <div className="socialIcons">
                            <ul>

                                <li>
                                    <a href="https://www.facebook.com/oxcash2/" rel="noreferrer" title="Facebook" target="_blank">
                                        <FaFacebookF />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/oxcashofficial/" rel="noreferrer" title="Instagram" target="_blank">
                                        <FaInstagram />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://t.me/oxcashchat" rel="noreferrer" title="Telegram" target="_blank">
                                        <FaTelegramPlane />
                                    </a>
                                </li>

                                <li>
                                    <a href="https://twitter.com/OxCash2" rel="noreferrer" title="Twitter" target="_blank">
                                        <FaTwitter />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://medium.com/@oxcash" rel="noreferrer" title="Medium" target="_blank">
                                        <FaMediumM />
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.reddit.com/r/Ox_cash/" rel="noreferrer" title="Reddit" target="_blank">
                                        <FaRedditAlien />
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.youtube.com/@oxcashpro" rel="noreferrer" title="Youtube" target="_blank">
                                        <FaYoutube />
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:hello@ox.cash" rel="noreferrer" title="Gmail" target="_blank">
                                        <FaRegEnvelope />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </CCol>
                </CRow>
            </div>
        </footer>
    )
}

export default Footer