import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";

import { configureStore } from '@reduxjs/toolkit';
import {rootReducer} from "./rootReducer";


export default configureStore(
    {
        reducer: rootReducer
    }
)

const useSelector = useReduxSelector;

const useDispatch = () => useReduxDispatch();
export const useAppDispatch = () => useDispatch();

export { useSelector, useDispatch };