import {createSlice} from "@reduxjs/toolkit";
import { fetchStakeInfo } from "../../state/stake";

const initialState = {
    depositedAt: 0,
    generated: 0,
    lastWithdrawn: 0,
    returned: 0,
    isStaked: false
}

const stakeSlice = createSlice({
    name: 'stake',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchStakeInfo.fulfilled, (state, action) => {
            const data = action.payload
            state.depositedAt = data.depositedAt
            state.generated = data.generated
            state.lastWithdrawn = data.lastWithdrawn
            state.returned = data.returned
            state.isStaked = data.isStaked
        })
    }
})

export default stakeSlice.reducer