import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountApprove, fetchAccountATXBalance, fetchAccountRegister, fetchAccountUSDTBalance, fetchAtxAccountApprove } from "./fetchAccountInfo";

export const fetchAccountInfo = createAsyncThunk("account/fetchAccountIn", async ( address ) => {
    try {
        const usdtBalance = await fetchAccountUSDTBalance({address})
        console.log(address)
        const isApproved = await fetchAccountApprove({address})
        const isRegistered = await fetchAccountRegister({address})

        return {
            usdtBalance: usdtBalance,
            isApproved,
            isRegistered
        }
    } catch(err){
        console.log(err)
        return {
            usdtBalance: 0,
            isApproved: false,
            isRegistered: false
        }
    }
})