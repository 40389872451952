
import React, { useEffect } from 'react';
import 'sweetalert2/dist/sweetalert2.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui/dist/js/coreui.min.js'
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Dashboard from './Pages/Dashboard/Dashboard';
import Partners from './Pages/Partners/Partners';
import Stats from './Pages/Stats/Stats';
import Faq from './Pages/Faq/Faq';

import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { Buffer } from 'buffer';
import { ethers } from "ethers";
import store from './redux/store'
import Treeviewdetail from './Pages/Treeviewdetail/Treeviewdetail';
import MyNfts from './Pages/MyNfts/MyNfts';
import Levels from './Pages/Levels/Levels';
import LevelsDetails from './Pages/LevelsDetails/LevelsDetails';

const getLibrary = (provider) => {
  return new ethers.providers.Web3Provider(provider);
}

const App = () => {

  useEffect(() => {
    window.onload = () => {
      setTimeout(() => {
        document.body.classList.remove('loadingpage');
      }, 0);
    }
  }, []);

  window.Buffer = Buffer;
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="home" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="mynfts" element={<MyNfts />} />
              <Route path="levels" element={<Levels />} />
              <Route path="levelsdetails" element={<LevelsDetails />} />
              <Route path="partners" element={<Partners />} />
              <Route path="stats" element={<Stats />} />
              <Route path="faq" element={<Faq />} />
              <Route path="dashboard-user" element={<Treeviewdetail />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </Web3ReactProvider>
  );
}

export default App;
