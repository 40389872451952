import { useCallback, useEffect, useState } from 'react';
import ocicon from '../../Assets/images/oc.png'
import { IKImage } from 'imagekitio-react';
import { levelPrices, names } from '../../config'
import { CopyButton } from '../../Components/CopyButton';
import { useNFTInstance } from '../../hooks/useContract';
import { nftAddress } from '../../config/nfts';
import useActiveWeb3React from '../../hooks/useWeb3';
import { shortAddress } from '../../utils';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import PlaceholderImage from "../../Assets/images/loading.gif";
import { LazyLoadImage } from "react-lazy-load-image-component";
const urlEndpoint = 'https://ik.imagekit.io/360kit/OxCash';

export const NFTDetail = ({ wallet, slot }) => {
    const { account } = useActiveWeb3React()

    const nftInstance = useNFTInstance(nftAddress[slot])
    const [tokenID, setTokenID] = useState(0)

    useEffect(() => {
        (async () => {
            if (wallet) {
                console.log(wallet)
                const _tokenId = await nftInstance.tokenOfOwnerByIndex(wallet, 0)
                console.log(_tokenId)
                setTokenID(parseInt(_tokenId))
            }
        })()
    }, [wallet, nftInstance, setTokenID])

    const addNFT = useCallback(async () => {
        if (!account) {
            toast.error("Please Connect Wallet")
            return
        }
        if (wallet.toLowerCase() !== account.toLowerCase()) {
            toast.error("Cannot Import Another User's NFT")
            return
        }
        const tokenURI = await nftInstance.tokenURI(tokenID)

        // Get the token data
        const response = await fetch(tokenURI); // can axios
        const tokenData = await response.json();

        console.log(tokenData)

        // Create the token object
        const tokenObject = {
            name: tokenData.name,
            description: tokenData.description,
            image: tokenData.image,
            external_url: tokenData.external_url ?? "",
            attributes: tokenData.attributes ?? ""
        };

        // Add the token to the user's MetaMask wallet
        try {
            await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC721',
                    options: {
                        address: nftAddress[slot],
                        tokenId: tokenID,
                        token: tokenObject
                    }
                }
            });
        } catch (error) {
            toast.error(`Error Importing NFT : ${error.reason ? error.reason : (error.data ? error.data.message : error.message)}`)
            console.log("error", error);
        }
    }, [account, tokenID, slot, nftInstance, wallet])

    return (
        <>
            <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                <div className="treebox">
                    <div className="row mb-4">
                        <div className="col-6">Slot {slot} ({names[slot - 1]})</div>
                        <div className="col-6">
                            <div className="headeline">
                                <img src={ocicon} alt="OC Logo" width="20" height="20" />
                                OC NFT
                            </div>
                        </div>
                    </div>
                    <div className="imgaeboxNft">
                        <div className="imgBxnft">
                            <LazyLoadImage src={require(`../../Assets/images/nfts/nft${slot}.gif`)}
                                width={480} height={480}
                                alt="NFT Image"
                                PlaceholderSrc={''}
                                effect="blur"
                            />
                            {/* <img src={require(`../../Assets/images/nfts/nft${slot}.gif`)} alt="NFT Logo" width={250} height={250} /> */}
                            {/* <IKImage
                            urlEndpoint={urlEndpoint}
                            path={`nft${slot}.gif`}
                            lqip={{ active: true }}
                            transformation={[{
                                height: 480,
                                width: 480,
                                crop: 'force',
                            }]}
                            loading="lazy"
                            alt="NFT Image"
                        /> */}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 d-flex footerBox mb-4">
                            <span>Your Token ID</span>
                            <span>{tokenID} <CopyButton text={tokenID} /></span>
                        </div>
                        <div className="col-12 d-flex footerBox mb-2">
                            <span>NFT Contract</span>
                            <span>{shortAddress(nftAddress[slot])} <CopyButton text={nftAddress[slot]} /> </span>
                        </div>
                    </div>
                    {/* <div className="row mt-3 downSticky mx-0">
                    <button className='am_btn' onClick={addNFT}>Add NFT to Wallet</button>
                </div> */}
                </div>
            </div>
        </>
    )
}