import { graphURL } from '../config';

import axios from 'axios';

async function fetchMembersLast24h(data=[], startLimit=0, endLimit=0) {
    // const nowTimestamp = Math.floor(Date.now() / 1000);

    // Calculate the timestamp for 24 hours ago

    if ( endLimit >= startLimit ) {
        return data.length
    }

    const options = {
        method: 'POST',
        url: graphURL,
        headers: {
            'content-type': 'application/json'
        },
        data: {
            query: `{
                users(where: {blockTimestamp_gt: ${endLimit}, blockTimestamp_lte: ${startLimit}}, orderBy: blockTimestamp, orderDirection: desc) {
                    id
                    blockTimestamp
                }
            }`
        }
    };

    const response = await axios.request(options);
    const res = response.data; // Response received from the API

    if ( res.data.users.length === 0 ) return data.length;
    // console.log(res.data.users[res.data.users.length - 1])
    let ifFoundDuplicate = false
    for ( let i = 0; i < data.length; i++ ) {
        const element = data[i];
        for ( let j = 0; j < res.data.users.length; j++ ) {
            if ( element.id === res.data.users[j].id ) {
                ifFoundDuplicate = true
                break
            }
        }
        if ( ifFoundDuplicate ) break
    }

    if ( ifFoundDuplicate ) {
        return data.length;
    }

    res.data.users.forEach(element => {
        data.push(element);
    });

    return fetchMembersLast24h(data, parseInt(data[data.length - 1].blockTimestamp - 1), endLimit);
}

export default fetchMembersLast24h;