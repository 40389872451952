import axios from "axios"
import { useCallback, useEffect, useMemo, useState } from "react"
import { profileAPI, referralAPI } from "../config"
import { useStakeContract } from "./useContract"

export const useOxProfile = ({userID}) => {
    const [data, setData] = useState({})
    const planC = useStakeContract();

    useEffect(() => {
        const inter = setInterval(async () => {
            try {
                if ( userID > 0 ) {
                    console.debug(userID, planC)
                    const address = await planC.userAddressByID(userID)
                    // console.debug(address)
                    const response = await axios.post(profileAPI, {
                        "wallet": address
                    })
                    console.debug(response)
                    setData(response.data.message)
                }
            } catch(err) {
                console.debug(err)
            }
        }, 6500)
        return () => clearInterval(inter)
    }, [planC, userID])

    useEffect(() => {
        (
            async () => {
                try {
                    if ( userID > 0 ) {
                        console.debug(userID)
                        const address = await planC.userAddressByID(userID)
                        console.debug(address)
                        const response = await axios.post(profileAPI, {
                            "wallet": address
                        })
                        console.debug(response)
                        setData(response.data.message)
                    }
                } catch(err) {
                    console.debug(err)
                }
            }
        )()
    }, [planC,userID])

    return useMemo(() => {
        return {
            data
        }
    }, [data])
}

export const useOxReferral = () => {
    const checkCode = useCallback(async (code) => {
        try {
            const response = await axios.post(referralAPI, {
                "referral": code
            })
            return response.data.walletAddress
        } catch(err) {
            console.log(err)
            return {}
        }
    }, [])

    return {checkCode}
}